import React from "react";

import { Layer } from "react-mapbox-gl";

import { FEET_PER_METER, PLASMA } from "../../constants";

import realWidthExpression from "./real-width-expression";

const PATH_WIDTH = 4;  // width of path lines in meters

const curbRampsCondition = avoid_curbs => (
  ["all",
    ["literal", avoid_curbs === 1],
    ["==", ["get", "footway"], ["literal", "crossing"]],
    ["==", ["get", "curbramps"], 0],
  ]
);

const tooSteepCondition = uphill => (
  ["all",
    ["==", ["get", "footway"], ["literal", "sidewalk"]],
    ["any",
      [">", ["abs", ["get", "incline"]], ["literal", uphill]],
    ],
  ]
);

const tooNarrowCondition = width => (
  ["any",
    ["all",
      ["==", ["get", "footway"], ["literal", "sidewalk"]],
      ["<", ["get", "width"], width],
    ],
    ["==", ["get", "elevator"], ["literal", 1]],
  ]
);

const SidewalksLayer = ({uphill, avoid_curbs, width}) => {
  const widthMeters = width / FEET_PER_METER;

  return (
    <>
      <Layer
        id="sidewalks-outline"
        type="line"
        sourceId="pedestrian"
        sourceLayer="transportation"
        layout={{ "line-cap": "round" }}
        paint={{
          "line-color": [
            "case",
            curbRampsCondition(avoid_curbs),
            "#999",
            tooSteepCondition(uphill),
            "#999",
            "#000",
          ],
          "line-opacity": {
            stops: [[12, 0.0], [18, 1]],
          },
          "line-width": realWidthExpression(0.5),
          "line-gap-width": realWidthExpression(PATH_WIDTH),
        }}
        before="bridge-street"
      />
      <Layer
        id="sidewalks"
        type="line"
        sourceId="pedestrian"
        sourceLayer="transportation"
        layout={{ "line-cap": "round" }}
        paint={{
          "line-color": [
            "case",
            curbRampsCondition(avoid_curbs),
            "#000000",
            tooSteepCondition(uphill),
            "#000000",
            (width === 0) ? ["literal", false] : tooNarrowCondition(widthMeters),
            PLASMA[1],
            PLASMA[PLASMA.length - 2],
          ],
          "line-opacity": 1,
          "line-width": realWidthExpression(PATH_WIDTH),
        }}
        before="bridge-street"
      />
    </>
  );
}

export default SidewalksLayer;
