import { FEET_PER_METER } from "./constants.js";

const travelModeMap = {
  "Manual wheelchair": "manual_wheelchair",
  "Powered wheelchair": "powered_wheelchair",
  "Cane": "cane",
  "Walking (normative)": "walking",
};

const routeServer = process.env.REACT_APP_ROUTESERVER;
const routeStreetsServer = process.env.REACT_APP_ROUTESTREETSSERVER;

export const SET_WIDTH_RESTRICTION = "SET_WIDTH_RESTRICTION";
export const SET_TRAVEL_MODE = "SET_TRAVEL_MODE";
export const GET_SIDEWALKSCORE = "GET_SIDEWALKSCORE";
export const RECEIVED_SIDEWALKSCORE = "RECEIVED_SIDEWALKSCORE";
export const FAILED_SIDEWALKSCORE = "FAILED_SIDEWALKSCORE";
export const CLEAR_SIDEWALKSCORE = "CLEAR_SIDEWALKSCORE";
export const SET_WALK_DISTANCE = "SET_WALK_DISTANCE";
export const CLICK_MAP = "CLICK_MAP";
export const SET_VIEW_MODE = "SET_VIEW_MODE";


export const setViewModeSidewalks = () => setViewMode("sidewalks");
export const setViewModeSidewalkScore = () => setViewMode("sidewalkscore");

export const setViewMode = mode => ({
  type: SET_VIEW_MODE,
  payload: mode,
});

export const setWidthRestriction = width => (dispatch, getState) => {
  dispatch({
    type: SET_WIDTH_RESTRICTION,
    payload: width,
  });
  fetchSidewalkScore({min_width: width}, dispatch, getState);
};

export const setTravelMode = (travelMode) => (dispatch, getState) => {
  dispatch({
    type: SET_TRAVEL_MODE,
    payload: { travelMode }
  });
  fetchSidewalkScore({}, dispatch, getState);
};

let walkDistanceTimeout;
export const setWalkDistance = walkDistance => (dispatch, getState) => {
  dispatch({
    type: SET_WALK_DISTANCE,
    payload: walkDistance,
  });

  clearTimeout(walkDistanceTimeout);
  walkDistanceTimeout = setTimeout(
    () => {
      fetchSidewalkScore({ walkDistance }, dispatch, getState);
    },
    50
  );
};

export const clickMap = (lon, lat) => (dispatch, getState) => {
  dispatch({
    type: CLICK_MAP,
    payload: { lon, lat }
  });
  fetchSidewalkScore({ lon, lat }, dispatch, getState);
};

export const receivedSidewalkScore = (lon, lat, pedestrian, street, sidewalkScore) => ({
  type: RECEIVED_SIDEWALKSCORE,
  payload: {lon, lat, pedestrian, street, sidewalkScore},
});

export const clearSidewalkScore = () => ({type: CLEAR_SIDEWALKSCORE});

export const fetchSidewalkScore  = (newParams, dispatch, getState) => {
  const state = getState();
  const { travelMode, walkshed, walkDistance, widthRestriction } = state;
  const queryParams = {
    lon: walkshed ? walkshed.lon : null,
    lat: walkshed ? walkshed.lat : null,
    max_cost: walkDistance / FEET_PER_METER,
    min_width: widthRestriction / FEET_PER_METER,
    ...newParams,
  }

  // Check if all necessary params are set (namely, lon and lat)
  if (!queryParams.lon || !queryParams.lat) return;

  const esc = encodeURIComponent;
  const queryURL = Object.keys(queryParams)
    .map(k => `${esc(k)}=${esc(queryParams[k])}`)
    .join("&");

  // const profile = travelModeMap[travelMode];
  //
  let profile = travelModeMap[travelMode];
  if (widthRestriction !== 0) {
    profile = profile+ `_width${widthRestriction}`;
  }

  const pedestrianReachURL = `${routeServer}/reachable/${profile}.json?${queryURL}`;
  const streetReachURL = `${routeStreetsServer}/reachable/walking.json?${queryURL}`;
  const pedestrianReachFetch = fetch(pedestrianReachURL);
  const streetReachFetch = fetch(streetReachURL);

  Promise.all([pedestrianReachFetch, streetReachFetch])
    .then(responses =>
      Promise.all(responses.map(res => res.ok ? res.json() : null))
    )
    .then(jsons => {
      for (let json of jsons) {
        if (json.status === "InvalidWaypoint") {
          dispatch({
            type: FAILED_SIDEWALKSCORE,
          });
          return;
        }
      }
      const pedestrian = jsons[0];
      const streets = jsons[1];
      const pedestrianReach = pedestrian.edges.features.reduce((a, v) => a + v.properties.length, 0);
      const streetReach = streets.edges.features.reduce((a, v) => a + v.properties.length, 0);
      const sidewalkScore = pedestrianReach / streetReach / 2;
      dispatch(receivedSidewalkScore(queryParams.lon, queryParams.lat, pedestrian, streets, sidewalkScore));
    })
    .catch(error => {});
}
