import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  Box,
  Button,
  Card,
  Heading,
  Tabs,
  Tab
} from "grommet";

import TravelModeControl from "./travelModeControl";
import WalkDistanceControl from "./walkDistanceControl";
import WidthRestrictionControl from "./widthRestrictionControl";
import * as AppActions from "../../actions";


class ControlBar extends Component {
  render() {
    const {
      actions,
      failure,
      sidewalkScore,
    } = this.props;

    return (
      <Box direction="column">
        <Tabs
          onActive={(tabIndex) => {
            if (tabIndex === 0) {
              actions.setViewModeSidewalks()
            } else {
              actions.setViewModeSidewalkScore()
            }
          }}
        >
          <Tab title="Sidewalks">
            <Heading level={2}>Accessible Sidewalks</Heading>
          </Tab>

          <Tab title="SidewalkScore">
            <Heading level={2}>
              {`SidewalkScore: ${sidewalkScore ? sidewalkScore.toFixed(2) : ""}`}
            </Heading>
            <Box direction="column" margin={{ bottom: "medium" }}>
              <WalkDistanceControl/>
            </Box>
            <Box direction="column">
              <Button
                secondary
                large
                label="Clear Walkshed"
                onClick={actions.clearSidewalkScore}
              />
            </Box>
          </Tab>
        </Tabs>
        <Card>
          <Box direction="column">
            <TravelModeControl />
          </Box>
          <Box direction="column">
            <WidthRestrictionControl />
          </Box>
          <Box direction="column" style={{color: "#FF0000"}}>
            { failure && failure }
          </Box>
        </Card>
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  failure: state.failure,
  sidewalkScore: state.sidewalkScore,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(AppActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ControlBar);
