import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  FormField,
  RadioButtonGroup,
} from "grommet";

import * as AppActions from "../../actions";


const TravelModeControl = ({actions, travelMode}) => (
  <FormField label="Travel mode" pad>
    <RadioButtonGroup
      name="Travel Mode"
      options={["Walking (normative)", "Manual wheelchair", "Powered wheelchair", "Cane"]}
      value={travelMode}
      onChange={(e) => {
        const target = e.target || null;
        if (target === null) return;
        actions.setTravelMode(target.value);
      }}
    />
  </FormField>
);


const mapStateToProps = state => ({
  travelMode: state.travelMode,
});


const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(AppActions, dispatch),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TravelModeControl);
