import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  FormField,
  RangeInput,
} from "grommet";

import * as AppActions from "../../actions";


const WidthRestrictionControl = ({actions, widthRestriction}) => (
  <FormField label={`Social distancing restriction: ${widthRestriction} feet`} pad>
    <RangeInput
      min={0}
      max={12}
      step={6}
      name="Select minimum sidewalk width"
      value={widthRestriction}
      label="Sidewalk width (feet)"
      onChange={(e) => {
        // TODO: this is a messed-up way to handle state (global variable). Use
        // component state?
        const target = e.target || null;
        if (target === null) return;
        actions.setWidthRestriction(parseInt(target.value));
      }}
    />
  </FormField>
);


const mapStateToProps = state => ({
  widthRestriction: state.widthRestriction,
});


const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(AppActions, dispatch),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WidthRestrictionControl);
