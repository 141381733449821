import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactMapboxGl from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import * as AppActions from "../../actions";

import MapSources from "../../components/MapSources";
import {
  OriginPathLayer,
  POILayer,
  SidewalksLayer,
  SidewalkScoreLayer,
  WalkshedLayer,
} from "../../components/MapLayers";
import travelModes from "../../travel-modes";

const center = [-122.333592, 47.605628];
const zoom = [15];

const MapboxGL = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
  minZoom: 10,
  maxZoom: 20,
  bearing: [0],
  pitch: [0],
});

const getTravelLayerString = (travelMode, widthRestriction) => {
  const widthString = (widthRestriction !== 0) ? `_width${widthRestriction}` : "";

  switch (travelMode) {
    case "Manual wheelchair":
      return `sidewalkscore_manual_wheelchair${widthString}`;
    case "Powered wheelchair":
      return `sidewalkscore_powered_wheelchair${widthString}`;
    case "Cane":
      return `sidewalkscore_cane${widthString}`;
    case "Walking (normative)":
      return `sidewalkscore_walking${widthString}`;
    default:
      return `sidewalkscore_wheelchair${widthString}`;
  }
};

class Map extends Component {
  render() {
    const {
      actions,
      poi,
      travelMode,
      viewMode,
      walkshed,
      walkshedOrigin,
      widthRestriction,
    } = this.props;


    const profile = travelModes[travelMode];

    let layers;

    if (viewMode === "sidewalks") {
      layers = (
        <>
          <SidewalksLayer
            uphill={profile.uphill}
            avoid_curbs={profile.avoid_curbs}
            width={widthRestriction}
          />
        </>
      );
    } else {
      layers = (
        <>
          <SidewalkScoreLayer
            layerName={getTravelLayerString(travelMode, widthRestriction)}
          />
          { walkshed && <WalkshedLayer walkshed={walkshed.edges} /> }
          { (poi && walkshedOrigin) && <OriginPathLayer poi={poi} origin={walkshedOrigin} /> }
          { poi &&
              <POILayer
                poi={poi}
                fillColor={walkshedOrigin ? "#77f" : "#f77" }
                borderColor={walkshedOrigin ? "#00f" : "#f00" }
              />
          }
        </>
      );
    }

    let handleClick;
    if (viewMode === "sidewalkscore") {
      handleClick = (m, e) => actions.clickMap(e.lngLat.lng, e.lngLat.lat);
    }

    //const handleClick = (m, e) => console.log(m.queryRenderedFeatures(e.point);

    return (
      <MapboxGL
        className="map"
        center={center}
        zoom={zoom}
        maxBounds={[[-122.714460, 47.406897], [-121.907342, 47.809213]]}
        style="mapbox://styles/mapbox/dark-v9"  // eslint-disable-line react/style-prop-object
        onMouseMove={(m, e) => {
          m.getCanvas().style.cursor = "pointer";
        }}
        onDrag={m => {
          m.getCanvas().style.cursor = "grabbing";
        }}
        onClick={handleClick}
      >
      <MapSources />
        {layers}
      </MapboxGL>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    poi: state.walkshed ? [state.walkshed.lon, state.walkshed.lat] : null,
    travelMode: state.travelMode,
    viewMode: state.viewMode,
    walkshed: state.walkshed ? state.walkshed : null,
    walkshedOrigin: state.walkshed ? state.walkshed.origin : null,
    widthRestriction: state.widthRestriction
  };
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(AppActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Map);
