import { combineReducers } from "redux";
import {
  CLEAR_SIDEWALKSCORE,
  FAILED_SIDEWALKSCORE,
  RECEIVED_SIDEWALKSCORE,
  SET_TRAVEL_MODE,
  SET_VIEW_MODE,
  SET_WIDTH_RESTRICTION,
  SET_WALK_DISTANCE,
} from "./actions";

const defaults = {
  failure: null,
  travelMode: "Walking (normative)",
  viewMode: "sidewalks",
  walkshed: null,
  walkDistance: 1000,
  sidewalkScore: null,
  widthRestriction: 6,
};

const handleFailure = (state = defaults.failure, action) => {
  switch (action.type) {
    case FAILED_SIDEWALKSCORE:
      return "Inaccessible start point for this pedestrian profile.";
    case RECEIVED_SIDEWALKSCORE:
      return null;
    default:
      return state;
  }
};

const handleSidewalkScore = (state = defaults.sidewalkScore, action) => {
  switch (action.type) {
    case FAILED_SIDEWALKSCORE:
    case CLEAR_SIDEWALKSCORE:
      return null;
    case RECEIVED_SIDEWALKSCORE:
      return action.payload.sidewalkScore;
    default:
      return state;
  }
};

const handleTravelMode = (state = defaults.travelMode, action) => {
  switch (action.type) {
    case SET_TRAVEL_MODE:
      return action.payload.travelMode;
    default:
      return state;
  }
};

const handleViewMode = (state = defaults.viewMode, action) => {
  switch (action.type) {
    case SET_VIEW_MODE:
      return action.payload;
    default:
      return state;
  }
};

const handleWalkshed = (state = defaults.walkshed, action) => {
  switch (action.type) {
    case RECEIVED_SIDEWALKSCORE:
      return {
        ...state,
        lon: action.payload.lon,
        lat: action.payload.lat,
        edges: action.payload.pedestrian.edges,
        nodes: action.payload.pedestrian.nodes,
        origin: action.payload.pedestrian.origin,
      };
    case CLEAR_SIDEWALKSCORE:
    case FAILED_SIDEWALKSCORE:
      return null;
    default:
      return state;
  }
};

const handleWalkDistance = (state = defaults.walkDistance, action) => {
  switch (action.type) {
    case SET_WALK_DISTANCE:
      return action.payload;
    default:
      return state;
  }
};

const handleWidthRestriction = (state = defaults.widthRestriction, action) => {
  switch (action.type) {
    case SET_WIDTH_RESTRICTION:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  failure: handleFailure,
  travelMode: handleTravelMode,
  viewMode: handleViewMode,
  walkshed: handleWalkshed,
  sidewalkScore: handleSidewalkScore,
  walkDistance: handleWalkDistance,
  widthRestriction: handleWidthRestriction,
});
