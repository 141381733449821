import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  FormField,
  RangeInput
} from "grommet";

import * as AppActions from "../../actions";


const WalkDistanceControl = ({actions, walkDistance}) => (
  <FormField label={`Walkshed distance: ${walkDistance} feet`} pad>
    <RangeInput
      min={500}
      max={2000}
      step={500}
      name="Select distance of travel"
      value={Math.round(walkDistance)}
      label="Distance of travel (feet))"
      onChange={(e) => {
        // TODO: this is a messed-up way to handle state (global variable). Use
        // component state?
        const target = e.target || null;
        if (target === null) return;
        actions.setWalkDistance(target.value);
      }}
    />
  </FormField>
);


const mapStateToProps = state => ({
  walkDistance: state.walkDistance,
});


const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(AppActions, dispatch),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WalkDistanceControl);
